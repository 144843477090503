<template>
    <div class="prtf-holder" v-loading="loading">
        <div class="prtf-hdr" :class="{ 'prtf-hdr--hidden': !show_header }">
            <div class="prtf-hdr-l">
                <date-picker
                    v-model="strategy_date"
                    type="date"
                    format="DD MMM YY"
                    value-type="date"
                    placeholder="Date"
                    :clearable="false"
                    @change="change_date">
                </date-picker>
                <el-select v-model="strategy_mode" @change="change_mode">
                    <el-option label="By value date" value="value"></el-option>
                    <el-option label="By trade date" value="trade"></el-option>
                </el-select>
            </div>
            <div class="prtf-hdr-r">
                <el-button type="primary" class="prtf-add-port-btn" @click="create_new_event">
                    <svg-icon icon-class="plus" /> 
                    New strategy
                </el-button>
                <el-button type="primary" plain class="prtf-collapse-btn" @click="collapse_all" v-if="!can_be_expanded">
                    <svg-icon icon-class="double_chevron_big_up" /> 
                    Collapse all
                </el-button>
                <el-button type="primary" plain class="prtf-collapse-btn" @click="expand_all" v-if="can_be_expanded">
                    <svg-icon icon-class="double_chevron_big_down" /> 
                    Expand all
                </el-button>
            </div>
        </div>
        <div class="prtf-body" @scroll='handle_body_scroll'></div>
    </div>
</template>

<script>
export default {
    name: 'strategy',
    components: {  },

    props: {
        client: {
            required: true,
        },
    },

    data(){
        return {
            show_header          : true,
            last_scroll_position : 0,
            active_items         : [],
            strategy_list        : [],
            strategy_date        : new Date,
            strategy_mode        : 'trade',
            loading              : false,
        }
    },

    computed: {
        can_be_expanded(){
            return this.active_items.length == 0
        },
    },

    methods: {
        collapse_all(){
            this.active_items = [];
        },
        expand_all(){
            this.active_items = ['summary'];
            this.strategy_list.forEach(strategy => {
                this.active_items.push(strategy._id)
            })
        },
        collapse_item(name){
            this.active_items = this.active_items.filter(item => { return item !== name })
        },
        expand_item(name){
            this.active_items.push(name)
        },
        handle_body_scroll(e){
            const currentScrollPosition = e.srcElement.scrollTop
            if (currentScrollPosition < 0) {
                return
            }
            if (Math.abs(currentScrollPosition - this.last_scroll_position) < 10) {
                return
            }
            this.show_header = currentScrollPosition < this.last_scroll_position
            this.last_scroll_position = currentScrollPosition
        },
        change_date(){
            this.get_list();
        },
        change_mode(){
            this.get_list();
        },
        is_item_active(id){
            return this.active_items.includes(id);
        },
        get_list(){
            // this.loading = true
            // this.portfolio_list = []
            // this.$store.dispatch('portfolio/getList', {
            //     client_id : this.client._id,
            //     mode      : this.portfolio_mode,
            //     date      : this.portfolio_date
            // }).then((response) => {
            //     this.portfolio_list = response
            //     this.loading = false
            // })
        },
        refresh(){
            // this.$refs.PortfolioSummary.get_summary();
            this.get_list();
        },

        create_new_event(){
            // this.$router.push({ path: '/strategy/' + this.client.id})
        }
    },

    mounted(){
        this.get_list();
    }
}
</script>
